/*
 *   File : calender.js
 *   Author : https://evoqins.com
 *   Description : Calender component
 *   Integrations : react-day-picker
 *   Version : 1.0.0
*/

import React, { Fragment, useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select'
import { DayPicker } from 'react-day-picker';

// import components
import { Icon } from '../Icon';
import { useClickOutside } from '../../Helper/helper';

import Colors from '../../Styles/color.module.scss'


// const pastMonth = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<Icon icon="arrow-down"
				width="16px"
				height="16px"
				color="#65308C" />
		</components.DropdownIndicator>
	);
};
// co

const CustomCalendar = React.memo((props) => {
	// state when single date has to be selected
	const [selected, setSelected] = useState(props.start === "DD-MM-YYYY" ? null : new Date(parseDate(props.start)));
	// state when date is a range
	const [range, setRange] = useState(props.start === "DD-MM-YYYY" ?
		{ from: null, to: null }
		:
		{
			from: new Date(parseDate(props.start)),
			to: new Date(parseDate(props.end))
		});
	// drop down open
	const [show, setShow] = useState(false);
	const calendarRef = useRef(null);
	const today = new Date();
	const current = new Date(today.getFullYear(), today.getMonth(), today.getDate())
	const [defaultMonth, setDefaultMonth] = useState(current);

	useEffect(() => {
		if (props.mode === "single") {
			setSelected(props.start === "DD-MM-YYYY" ? null : new Date(parseDate(props.start)));
		}
		// eslint-disable-next-line
	}, [props.start])

	useClickOutside(calendarRef, () => {
		setShow(false);
	});

	useEffect(() => {
		if (props.mode === "single") {
			if (selected && isNaN(selected.getTime())) {
				props.selectDate("DD-MM-YYYY");
				setSelected(null)
			}
		}
		// eslint-disable-next-line
	}, [selected, props.mode]);

	useEffect(() => {
		if (props.mode !== "single") {
			if (range && range.from && isNaN(range.from.getTime())) {
				props.selectDate({
					from: "DD-MM-YYYY",
					to: "DD-MM-YYYY"
				});
				setRange({
					from: null,
					to: null
				})
			}
		}
		// eslint-disable-next-line
	}, [range, props.mode]);

	useEffect(() => {
		if (props.start === "DD-MM-YYYY" && props.mode !== "single") {
			setRange({
				from: null,
				to: null
			})
		}
		// eslint-disable-next-line
	}, [props.start, props.mode]);


	useEffect(() => {
		props.menuOpen(show);
		//eslint-disable-next-line
	}, [show])

	useEffect(() => {
		if (props.mode === 'single' && selected) {
			setShow(false);
			props.selectDate(selected); // Passing the selected single date directly
			setDefaultMonth(selected)
		} else if (range && range.from && range.to) {
			setShow(false);
			props.selectDate(range);
			setDefaultMonth(range.from)
		}
		//eslint-disable-next-line
	}, [selected, range]);

	useEffect(() => {
		if (props.bodyDisable) {
			document.body.style.overflow = show ? 'hidden' : 'auto';
		}

		return () => {
			// Cleanup to ensure overflow is reset when the component unmounts
			if (props.bodyDisable) {
				document.body.style.overflow = 'auto';
			}
		};
	}, [show, props.bodyDisable]);

	function handleCalendar() {
		setShow(true);
	}

	// default date function when date is not passed
	function parseDate(dateString) {
		if (dateString === "DD-MM-YYYY" || !dateString) {
			return false // Return current date for "DD-MM-YYYY" or empty date string
		}
		const parts = dateString.split('-');
		if (parts.length !== 3) {
			return new Date(NaN); // Return invalid date if format is incorrect
		}
		return new Date(parts[2], parts[1] - 1, parts[0]);
	}

	function _clearDate(event) {
		event.stopPropagation()

		if (props.mode !== "single") {
			setRange({ from: null, to: null });
			props.selectDate({
				from: "DD-MM-YYYY",
				to: "DD-MM-YYYY"
			});
		} else {
			setSelected(null);
			props.selectDate("DD-MM-YYYY")
		}
	}

	const customStyles = {
		container: (defaultStyles, state) => ({
			...defaultStyles,
		}),
		placeholder: (defaultStyles) => ({
			...defaultStyles,
			margin: "0",
			color: Colors.medium_gray,
			fontSize: "12px",
			lineHeight: "18px",
			paddingLeft: "2px",
			fontFamily: "Montserrat-Regular"
		}),
		input: (defaultStyles) => ({
			...defaultStyles,
			margin: "0",
			padding: "0"
		}),
		control: (defaultStyles, state) => ({
			...defaultStyles,
			borderRadius: "8px",
			border: state.isFocused ? `1px solid ${Colors.rebecca_purple}` : `1px solid ${Colors.silver_sand}`,
			background: "transparent",
			padding: "6px 8px",
			boxShadow: "none",
			"&:hover": {
				border: state.isFocused ? `1px solid ${Colors.rebecca_purple}` : `1px solid ${Colors.silver_sand}`,
				cursor: 'pointer',
			},
			"&[aria-disabled = true]": {
				background: Colors.light_lavender
			}
		}),
		valueContainer: (defaultStyles) => ({
			...defaultStyles,
			padding: "0px",
		}),


		menuList: (defaultStyles, state) => ({
			...defaultStyles,
			position: 'relative', // Ensure relative positioning for the scrollbar to appear correctly
			'&::-webkit-scrollbar': {
				width: '8px',
				height: '8px',
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: Colors.rebecca_purple,
			},
		}),

		dropdownIndicator: (defaultStyles, state) => ({
			...defaultStyles,
			padding: "0",
			marginLeft: "4px",
			transition: "transform 0.2s",
			transform: state.selectProps.menuIsOpen === true ? "rotate(-180deg)" : ""
		}),
		menu: (defaultStyles, state) => ({
			...defaultStyles,
			overflow: "hidden",
			padding: "0px",
			borderRadius: "16px",
			width: "150px",
			height: "200px",
			border: `1px solid ${Colors.athens_gray}`,
			boxShadow: "5px 3px 10px 0px #0000001A"
		}),
		option: (defaultStyles, state) => ({
			...defaultStyles,
			padding: "8px",
			fontFamily: "Montserrat-Medium",
			fontSize: "12px",
			fontStyle: " normal",
			fontWeight: "500",
			lineHeight: "18px",
			letterSpacing: "0.07em",
			backgroundColor: Colors.white,
			color: state.isSelected ? `${Colors.rebecca_purple} !important` : `${Colors.eerie_black} !important`,

			// borderRadius: "12px",
			cursor: "pointer",
			"&:hover": {
				backgroundColor: Colors.light_lavender,
			},
			"&:not(:last-child)": {
				borderBottom: `solid 1px ${Colors.bright_gray}`,
			},

		}),

		singleValue: (defaultStyles) => ({
			...defaultStyles,
			fontSize: "12px",
			lineHeight: "20px",
			marginLeft: "0",
			fontFamily: "Montserrat-Medium",
			color: Colors.charleston_green
		}),
	};


	return (
		<Fragment>
			<label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">
				{props.label}
				{props.postfix && <span className="color-deep-carmine-pink">*</span>}
			</label>
			<div className='position-relative'>
				{
					props.mode === "single" ?

						selected === null ?
							<p className={`${props.className}  color-dark-charcoal e-font-14 e-montserrat-regular d-flex align-items-center justify-content-between e-line-height-24 bg-white border-1px border-color-pale-gray p-2 border-radius-8px cursor-pointer mb-0`}
								onClick={handleCalendar}>
								DD-MM-YYYY
								<Icon icon="calendar" size={20} />
							</p>
							:
							<p className={`${props.className} color-charleston-green e-font-16 e-montserrat-medium d-flex align-items-center justify-content-between e-line-height-24 bg-white border-1px border-color-pale-gray p-2 border-radius-8px cursor-pointer mb-0`}
								onClick={handleCalendar}>
								{props.start}
								<Icon icon="close" size={20} onClick={_clearDate} />
							</p>
						:
						range && range.from === null ?
							<p className={`${props.className}  color-dark-charcoal e-font-14 e-montserrat-regular d-flex align-items-center justify-content-between e-line-height-24 bg-white border-1px border-color-pale-gray p-2 border-radius-8px cursor-pointer mb-0`}
								onClick={handleCalendar}>
								DD-MM-YYYY - DD-MM-YYY
								<Icon icon="calendar" size={20} />
							</p>
							:
							<p className={`${props.className} d-flex align-items-center justify-content-between e-line-height-24 bg-white border-1px border-color-pale-gray p-2 border-radius-8px cursor-pointer color-gray e-font-14 e-montserrat-regular mb-0`}
								onClick={handleCalendar}>
								{props.start} - {props.end}
								<Icon icon="close" size={20} onClick={_clearDate} />
							</p>
				}

				{show && (
					<Fragment>
						<div ref={calendarRef} className='position-fixed z-index-99  e-calender'>
							<DayPicker
								id="test"
								captionLayout="dropdown"
								disabled={props.disabledDays}
								mode={props.mode}
								defaultMonth={defaultMonth}
								// defaultMonth={pastMonth}
								selected={props.mode === 'single' ? selected : range}
								onSelect={props.mode === 'single' ? setSelected : setRange}
								fromYear={1947}
								toYear={new Date().getFullYear()}
								components={{
									Dropdown: ({ caption, value, children, ...rest }) => {
										const options = rest.name === "years" ? children.sort((a, b) => Number(b.key) - Number(a.key)).map((item) => ({
											label: item.props.children,
											value: item.key
										})) : children.map((item) => ({
											label: item.props.children,
											value: item.key
										}));

										// Safeguard against undefined values
										const selectedValue = { label: caption, value };
										return (
											<Select
												options={options}
												value={selectedValue}
												className='me-2'
												styles={customStyles}
												isSearchable={false}
												// menuIsOpen={true}
												components={{
													DropdownIndicator,
													IndicatorSeparator: null,
												}}
												onChange={(selectedOption) => {
													// Ensure selectedOption exists and pass the correct value to rest.onChange
													if (selectedOption) {
														// Calling the rest.onChange provided by DayPicker to update the value
														rest.onChange({
															target: { value: selectedOption.value }
														});
													}
												}}
											/>
										);
									}
								}}
							/>
						</div>
						<div className='position-fixed w-100 h-100 e-bg-black e-calender-backdrop z-index-50 top-0 start-0' onClick={() => setShow(false)}></div>
					</Fragment>
				)}

				{
					props.error &&
					<p className='position-absolute color-deep-carmine-pink  e-montserrat-medium e-font-12  e-line-height-16 mb-0  margin-6px-top'>
						{props.error}
					</p>
				}
			</div>
		</Fragment>
	);
});

CustomCalendar.defaultProps = {
	menuOpen: () => { },
	start: "DD-MM-YYYY",
	end: "DD-MM-YYYY",
	bodyDisable: true
};

export default CustomCalendar;
