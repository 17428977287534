
/*
 *   File : delete-confirmation.js
 *   Author URI : https://evoqins.com
 *   Description : The modal component used for showing confirmation screen.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react";


// Custom styles
import style from "../../Styles/Components/delete-confirmation.module.scss";
import Icon from "../Icon/icon";
import { LinkButton, PrimaryButton, SecondaryButton } from "../Buttons";


const DeleteConfirmationModal = (props) => {
    const [view, setView] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("delete-modal");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
        //eslint-disable-next-line
    }, [props.close]);


    const _handleDelete = async () => {
        try {
            const result = await props.delete();
            if (result === true) {
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                props.updateList()
            }
        } catch (err) {

        }
    }
    return (
        <Fragment>
            <div className={`modal fade ${style.e_modal}`}
                id="delete-modal"
                tabIndex="-1"
                aria-labelledby="delete-modal"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    {
                        view === true ?
                            <div className={`modal-content padding-32px-all ${style.e_content} `}>
                                <Icon icon="back"
                                    size={24}
                                    onClick={() => setView(false)}
                                    className="cursor-pointer" />
                                <img src={props.document}
                                    width={"auto"}
                                    alt="Document"
                                    className="h-400px object-fit-contain" />
                            </div>
                            :
                            <div className={`modal-content padding-32px-all ${style.e_content} `}>
                                <div className="d-flex justify-content-between align-items-center mb-4">
                                    <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                        {props.title}
                                    </h5>
                                    <Icon icon="close"
                                        size={18}
                                        id="close-modal"
                                        data-bs-dismiss="modal"
                                        className='cursor-pointer' />
                                </div>
                                <div className="margin-40px-bottom">
                                    <p className="e-montserrat-medium e-font-16 e-line-height-24 color-dark-charcoal mb-2">{props.description}</p>
                                    {props.document &&
                                        <LinkButton text="View Document"
                                            handleClick={() => setView(true)} />}
                                </div>
                                <div className="d-flex gap-8px justify-content-end">
                                    <SecondaryButton label="Cancel"
                                        cancel="modal"
                                        className="padding-14px-tb padding-50px-lr" />
                                    <div className="col-4">

                                        <PrimaryButton label={props.label ? props.label : "Delete"}
                                            className="padding-14px-tb w-100"
                                            handleClick={_handleDelete}
                                            loader={props.loader}
                                            disabled={props.loader} />
                                    </div>
                                </div>
                            </div>}
                </div>
            </div>
        </Fragment>
    )
}

export default DeleteConfirmationModal