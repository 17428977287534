
/*
 *   File : view-document.js
 *   Author URI : https://evoqins.com
 *   Description : The modal component used for viewing documents.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect } from "react";

// Custom styles
import style from "../../Styles/Components/delete-confirmation.module.scss";

import Icon from "../Icon/icon";

const ViewDocument = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("view-document");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
        //eslint-disable-next-line
    }, [props.close]);


    return (
        <Fragment>
            <div className={`modal fade ${style.e_modal}`}
                id="view-document"
                tabIndex="-1"
                aria-labelledby="view-document"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>

                    <div className={`modal-content padding-32px-all ${style.e_content} `}>
                        <div className="d-flex justify-content-end align-items-center">

                            <Icon icon="close"
                                size={18}
                                id="close-modal"
                                data-bs-dismiss="modal"
                                className='cursor-pointer' />
                        </div>
                        <img src={props.document}
                            width={"auto"}
                            alt="Document"
                            className="h-400px object-fit-contain" />
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default ViewDocument