/*
 *   File : manage-portfolio.js
 *   Author : https://evoqins.com
 *   Description : Container screen to create a new modal portfolio in the application.
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { Modal } from "bootstrap";

// Custom components
import { CustomCheckBox, CustomSelect, CustomTextInput1, PercentageInput, SearchSelect } from "../../../../Components/FormElements";
import { Breadcrumb } from "../../../../Components/Others";
import { LinkButton, PrimaryButton, SecondaryButton } from "../../../../Components/Buttons";
import { CustomTabBar } from "../../../../Components/Tab";
import { Icon } from "../../../../Components/Icon";
// import { DeleteConfirmationModal } from "../../../../Components/Modal";

// Services
import APIService from "../../../../Services/api-service";

const BASKET_OPTIONS = [
    {
        label: "Active basket",
        id: 1
    },
    {
        label: "Passive basket",
        id: 2
    }
]

// const FUND_TYPES = [
//     {
//         label: "Equity",
//         value: 1
//     },
//     {
//         label: "Debt",
//         value: 2
//     }
// ]



const ManageModelPortfolio = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const yearRef = useRef(null);

    const basketRef = useRef(null);

    const benchmarkRef = useRef(null);

    const [isPrimary, setIsPrimary] = useState(false);

    const [tabIndex, setTabIndex] = useState(location.state.tab ? location.state.tab : BASKET_OPTIONS[0].id);

    const [startYearOptions, setStartYearOptions] = useState([]);
    const [endYearOptions, setEndYearOptions] = useState([]);

    const [startYear, setStartYear] = useState(null);
    const [startYearError, setStartYearError] = useState('');

    const [endYear, setEndYear] = useState(null);
    const [endYearError, setEndYearError] = useState('');

    const [benchmark, setBenchmark] = useState("");
    const [equity, setEquity] = useState("");
    const [debt, setDebt] = useState("");
    const [equityBse, setEquityBse] = useState("");
    const [gold, setGold] = useState("");
    const [returns, setReturns] = useState("");
    const [returnsError, setReturnsError] = useState("");

    const [passiveBenchmark, setPassiveBenchmark] = useState("");
    const [passiveEquity, setPassiveEquity] = useState("");
    const [passiveDebt, setPassiveDebt] = useState("");
    const [passiveEquityBse, setPassiveEquityBse] = useState("");
    const [passiveGold, setPassiveGold] = useState("");
    const [passiveReturns, setPassiveReturns] = useState("");
    const [passiveReturnsError, setPassiveReturnsError] = useState("");

    const [apiLoader, setApiLoader] = useState(false);

    // eslint-disable-next-line
    const [error, setError] = useState("");


    const [fundList, setFundList] = useState([]);

    // const [confirmFunds, setConfirmFund] = useState(false);

    const [fundTypes, setFundTypes] = useState([
        {
            id: 1,
            fund_type: "",
            allocation: "",
            funds: [{
                fund: "",
                fund_id: null,
                allocation: "",
                error: ["", ""],
                allocation_id: null,
                primary_fund: false,
                basket_type: tabIndex === 1 ? "ACTIVE" : "PASSIVE",
                asset_class: null,
            }],
            fund_error: "",
            allocation_error: "",
        }
    ])

    const [passiveFundTypes, setPassiveFundTypes] = useState([
        {
            id: 1,
            fund_type: "",
            allocation: "",
            funds: [{
                fund: "",
                fund_id: null,
                allocation: "",
                error: ["", ""],
                allocation_id: null,
                primary_fund: false,
                basket_type: tabIndex === 1 ? "ACTIVE" : "PASSIVE",
                asset_class: null,
            }],
            fund_error: "",
            allocation_error: ""
        }
    ])

    useEffect(() => {
        _getStartYear();
        _getFundList(null);
    }, []);

    // useEffect(() => {
    //     if (confirmFunds === true) {
    //         const modal = new Modal(document.getElementById("delete-modal"));
    //         modal.show()
    //     }
    // }, [confirmFunds]);

    useEffect(() => {
        if (location.state !== null && location.state.portfolio !== null) {
            setStartYear({
                label: location.state.portfolio.start_date === 0 ? "Below 1" : location.state.portfolio.start_date,
                value: location.state.portfolio.start_date
            });
            setEndYear({
                label: location.state.portfolio.end_date === 0 ? "Below 1" : location.state.portfolio.end_date,
                value: location.state.portfolio.end_date
            });
            setIsPrimary(location.state.portfolio.is_primary);
            let fund_types = []
            let passive_fund_types = []
            fund_types = Object.keys(location.state.portfolio.funds[`active`].fund_data).map((assetClass, index) => ({
                fund_type: assetClass,
                id: index + 1,
                fund_error: "",
                allocation: location.state.portfolio.funds[`active`].fund_data[assetClass][0].allocation,
                allocation_error: "",
                funds: location.state.portfolio.funds[`active`].fund_data[assetClass].map(fund => ({
                    fund: fund.fund_name,
                    fund_id: fund.fund_id,
                    allocation: fund.allocation,
                    primary_fund: fund.primary_fund,
                    allocation_id: fund.allocation_id,
                    error: ["", ""],
                    asset_class: assetClass,
                    basket_type: 'ACTIVE'
                }))
            }));


            if (fund_types.length > 0) {
                setFundTypes(fund_types);
            }

            passive_fund_types = Object.keys(location.state.portfolio.funds[`passive`].fund_data).map((assetClass, index) => ({
                fund_type: assetClass,
                id: index + 1,
                fund_error: "",
                allocation: location.state.portfolio.funds[`passive`].fund_data[assetClass][0].allocation,
                allocation_error: "",
                funds: location.state.portfolio.funds[`passive`].fund_data[assetClass].map(fund => ({
                    fund: fund.fund_name,
                    fund_id: fund.fund_id,
                    allocation: fund.allocation,
                    primary_fund: fund.primary_fund,
                    allocation_id: fund.allocation_id,
                    error: ["", ""],
                    asset_class: assetClass,
                    basket_type: 'PASSIVE'
                }))
            }));

            if (passive_fund_types.length > 0) {
                setPassiveFundTypes(passive_fund_types);
            }

            let benchmark_data = {}
            if (location.state.portfolio.funds.active.benchmark_data) {
                benchmark_data = location.state.portfolio.funds.active.benchmark_data
                setBenchmark(benchmark_data.benchmark_name !== null ? benchmark_data.benchmark_name : "");
                setEquity(benchmark_data.equity !== null ? benchmark_data.equity : "");
                setDebt(benchmark_data.debt !== null ? benchmark_data.debt : "");
                setEquityBse(benchmark_data.equity_bse !== null ? benchmark_data.equity_bse : "");
                setGold(benchmark_data.gold !== null ? benchmark_data.gold : "");
                setReturns(benchmark_data.returns !== null ? benchmark_data.returns : "");
            }

            if (location.state.portfolio.funds.passive.benchmark_data) {
                benchmark_data = location.state.portfolio.funds.passive.benchmark_data
                setPassiveBenchmark(benchmark_data.benchmark_name !== null ? benchmark_data.benchmark_name : "");
                setPassiveEquity(benchmark_data.equity !== null ? benchmark_data.equity : "");
                setPassiveDebt(benchmark_data.debt !== null ? benchmark_data.debt : "");
                setPassiveEquityBse(benchmark_data.equity_bse !== null ? benchmark_data.equity_bse : "");
                setPassiveGold(benchmark_data.gold !== null ? benchmark_data.gold : "");
                setPassiveReturns(benchmark_data.returns !== null ? benchmark_data.returns : "");
            }

        }
    }, [location.state])

    // primary duration
    function _handlePrimary(status) {
        setIsPrimary(status);
    }

    function _handleNavigate() {
        navigate(-1);
    }


    // handle tab
    function _handleTabIndex(tab_index) {
        setTabIndex(tab_index);
    }

    // handle start year value
    function _handleStartYearValue(select_value) {
        setStartYear(select_value);
        setStartYearError('');
    }

    // handle end year value
    function _handleEndYearValue(select_value) {
        setEndYear(select_value);
        setEndYearError('');
    }

    // options of years
    function _getStartYear() {
        let years_options = []

        for (let i = 1; i <= 15; i++) {
            years_options.push({
                label: i,
                value: i
            })
        }
        years_options.push({
            label: "Above 15",
            value: 16
        });

        setStartYearOptions(years_options);
        setEndYearOptions(years_options);
    }

    // handle add fund
    function _handleAddFund() {
        if (tabIndex === 1) {
            const fund_types = [...fundTypes]
            fund_types.push({
                id: fund_types.length + 1,
                fund_type: "",
                allocation: "",
                funds: [{
                    fund: "",
                    fund_id: null,
                    allocation: "",
                    error: ["", ""],
                    allocation_id: null,
                    primary_fund: false,
                    asset_class: null,
                    basket_type: tabIndex === 1 ? "ACTIVE" : "PASSIVE"
                }],
                fund_error: "",
                allocation_error: ""
            });
            setFundTypes(fund_types);
        } else {
            const fund_types = [...passiveFundTypes]
            fund_types.push({
                id: fund_types.length + 1,
                fund_type: "",
                allocation: "",
                funds: [{
                    fund: "",
                    fund_id: null,
                    allocation: "",
                    error: ["", ""],
                    allocation_id: null,
                    primary_fund: false,
                    asset_class: null,
                    basket_type: tabIndex === 1 ? "ACTIVE" : "PASSIVE"
                }],
                fund_error: "",
                allocation_error: ""
            });
            setPassiveFundTypes(fund_types);
        }
    }

    // handle fund type
    function _handleFundType(input_value, index, error_index) {
        if (tabIndex === 1) {
            const fund_types = [...fundTypes]
            fund_types[index]['fund_type'] = input_value
            fund_types[index]['fund_error'] = ""
            setFundTypes(fund_types);
        } else {
            const fund_types = [...passiveFundTypes]
            fund_types[index]['fund_type'] = input_value
            fund_types[index]['fund_error'] = ""
            setPassiveFundTypes(fund_types);
        }
    }

    // handle fund
    function _handleFundValue(input_value, index, error_index, key, fund_id) {
        if (tabIndex === 1) {
            const fund_types = [...fundTypes]
            const fund_list = [...fund_types[index].funds]
            fund_list[key]['fund'] = input_value
            fund_list[key]['fund_id'] = fund_id
            fund_list[key]['error'][error_index] = ""
            fund_types[index].funds = fund_list
            setFundTypes(fund_types);
        } else {
            const fund_types = [...passiveFundTypes]
            const fund_list = [...fund_types[index].funds]
            fund_list[key]['fund'] = input_value
            fund_list[key]['fund_id'] = fund_id
            fund_list[key]['error'][error_index] = ""
            fund_types[index].funds = fund_list
            setPassiveFundTypes(fund_types);
        }
    }

    // set primary for fund
    function _handlePrimaryFund(check, index, key) {
        if (tabIndex === 1) {
            const fund_types = [...fundTypes]
            const fund_list = [...fund_types[index].funds];


            for (let i = 0; i < fund_list.length; i++) {
                if (i === key) {
                    fund_list[key]['primary_fund'] = check;
                } else {
                    fund_list[i]['primary_fund'] = false
                }

            }

            setFundTypes(fund_types);
        } else {
            const fund_types = [...passiveFundTypes]
            const fund_list = [...fund_types[index].funds];


            for (let i = 0; i < fund_list.length; i++) {
                if (i === key) {
                    fund_list[key]['primary_fund'] = check;
                } else {
                    fund_list[i]['primary_fund'] = false
                }

            }

            setPassiveFundTypes(fund_types);
        }
    }
    function _handleAddSecondaryFund(index) {
        if (tabIndex === 1) {
            const fund_types = [...fundTypes]
            const fund_list = [...fund_types[index].funds]
            fund_list.push({

                fund: "",
                fund_id: null,
                allocation: "",
                error: ["", ""],
                primary_fund: false,
                allocation_id: null,

            });

            fund_types[index].funds = fund_list
            setFundTypes(fund_types);
        } else {
            const fund_types = [...passiveFundTypes]
            const fund_list = [...fund_types[index].funds]
            fund_list.push({

                fund: "",
                fund_id: null,
                allocation: "",
                error: ["", ""],
                primary_fund: false,
                allocation_id: null,

            });

            fund_types[index].funds = fund_list
            setPassiveFundTypes(fund_types);
        }
    }

    // handle allocation
    function _handleAllocation(input_value, index, error_index, key) {
        if (tabIndex === 1) {
            const fund_types = [...fundTypes]
            fund_types[index]['allocation'] = input_value
            fund_types[index]['allocation_error'] = ""
            setFundTypes(fund_types);

        } else {
            const fund_types = [...passiveFundTypes]
            fund_types[index]['allocation'] = input_value
            fund_types[index]['allocation_error'] = ""
            setPassiveFundTypes(fund_types);
        }
    }

    // handle benchmark
    function _handleBenchmark(input_value, id) {
        if (tabIndex === 1) {
            setBenchmark(input_value);
        } else {
            setPassiveBenchmark(input_value);
        }
    }


    // handle equity
    function _handleEquity(input_value) {
        if (input_value <= 100) {
            if (tabIndex === 1) {
                setEquity(input_value);
            } else {
                setPassiveEquity(input_value);
            }
        }
    }

    // handle debt
    function _handleDebt(input_value) {
        if (input_value <= 100) {
            if (tabIndex === 1) {
                setDebt(input_value);
            } else {
                setPassiveDebt(input_value);
            }
        }
    }

    // handle Equity BSE
    function _handleEquityBse(input_value) {
        if (input_value <= 100) {
            if (tabIndex === 1) {
                setEquityBse(input_value);
            } else {
                setPassiveEquityBse(input_value);
            }
        }
    }

    // handle gold
    function _handleGold(input_value) {
        if (input_value <= 100) {
            if (tabIndex === 1) {
                setGold(input_value);
            } else {
                setPassiveGold(input_value);
            }
        }
    }

    // handle returns
    function _handleReturns(input_value) {
        if (input_value <= 100) {
            if (tabIndex === 1) {
                setReturns(input_value);
                setReturnsError("");
            } else {
                setPassiveReturns(input_value);
                setPassiveReturnsError("");
            }
        }
    }

    // function _confirm() {
    //     return true
    // }



    // delete fund
    function _handleDeleteFund(index, secondary_index, is_secondary) {
        if (tabIndex === 1) {
            let fund_types = [...fundTypes]
            if (is_secondary) {
                fund_types[index].funds.splice(secondary_index, 1);
            } else {
                fund_types.splice(index, 1);
            }
            setFundTypes(fund_types);
        } else {
            let fund_types = [...passiveFundTypes]
            if (is_secondary) {
                fund_types[index].funds.splice(secondary_index, 1);
            } else {
                fund_types.splice(index, 1);
            }
            setPassiveFundTypes(fund_types);
        }
    }

    function _handleCreatePortfolio() {
        let valid = true;
        const fund_types = tabIndex === 1 ? [...fundTypes] : [...passiveFundTypes]
        const return_value = tabIndex === 1 ? returns : passiveReturns;

        if (return_value === "") {
            benchmarkRef.current.scrollIntoView({ block: 'start' });
            if (tabIndex === 1) {
                setReturnsError("Returns is required");
            } else {
                setPassiveReturnsError("Returns is required");
            }
            valid = false;
        }
        for (let i = 0; i < fund_types.length; i++) {
            // const fund_list = [...fund_types[i].funds];
            if (fund_types[i]["fund_type"] === "") {
                basketRef.current.scrollIntoView({ block: 'start' });
                fund_types[i]["fund_error"] = "Fund type is required";
                valid = false;
            }
            if (fund_types[i]["allocation"] === "") {
                basketRef.current.scrollIntoView({ block: 'start' });
                fund_types[i]["allocation_error"] = "Allocation is required";
                valid = false;
            }
            // for (let j = 0; j < fund_list.length; j++) {
            //     if (fund_list[j]["fund_id"] !== null) {
            //         if (!fund_list.some(item => item.primary_fund === true)) {
            //             if (j === fund_list.length - 1) {
            //                 basketRef.current.scrollIntoView({ block: 'start' });
            //                 toast.dismiss()
            //                 toast.error("Atleast one primary fund is required", {
            //                     type: 'error'
            //                 });
            //                 valid = false;
            //             }
            //         }
            //     }
            //     if (fund_list[j]["fund_id"] === null) {
            //         basketRef.current.scrollIntoView({ block: 'start' });
            //         fund_list[j]["error"][0] = "Select a fund";
            //         valid = false;
            //     }

            // }
        }
        if (startYear !== null && endYear !== null && endYear.value <= startYear.value) {
            yearRef.current.scrollIntoView({ block: 'start' });
            setEndYearError("End tenure should be greater than start tenure");
            valid = false;
        }

        if (endYear === null) {
            yearRef.current.scrollIntoView({ block: 'start' });
            setEndYearError("Select end year");
            valid = false;
        }

        if (startYear === null) {
            yearRef.current.scrollIntoView({ block: 'start' });
            setStartYearError("Select start year");
            valid = false;
        }
        const assetClassCounts = fund_types.reduce((counts, item) => {
            counts[item.fund_type] = (counts[item.fund_type] || 0) + 1;
            return counts;
        }, {});


        // Check if any asset_class is repeated more than once
        const hasDuplicates = Object.values(assetClassCounts).some(count => count > 1);
        if (hasDuplicates) {
            valid = false;
            toast.dismiss()
            toast.error("Asset class cannot be repeated", {
                type: 'error'
            });
        }
        if (valid === true) {
            // const total_allocation = []
            // fundTypes.map((element) => {
            //     return (
            //         total_allocation.push(
            //             element.funds.reduce((a, b) => a + parseInt(b.allocation), 0)
            //         )
            //     )
            // })
            // if (total_allocation.reduce((a, b) => Number(a) + Number(b.allocation)) !== 100) {
            //     setError("Allocation should be 100%");
            // } else {
            _managePortfolio(true)
            // navigate("/manage-portfolio")
            // }

        }
        if (tabIndex === 1) {
            setFundTypes(fund_types)
        } else {
            setPassiveFundTypes(fund_types)
        }
    }

    // API - get funds
    const _getFundList = (search) => {
        const url = "/model-portfolio/get-fund";

        const request = {
            query: search
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                const fund_list = response.data.map((item) => {
                    return ({
                        name: item.label,
                        id: item.value
                    })
                })
                setFundList(fund_list)
            } else {
                setFundList([]);
            }
        })
    }

    // API - manage portfolio
    const _managePortfolio = (validate) => {
        setApiLoader(true);

        let active_funds_exist = fundTypes.filter((item) => item.funds.some((fund) => fund.fund_id !== null))

        let passive_funds_exist = passiveFundTypes.filter((item) => item.funds.some((fund) => fund.fund_id !== null))


        if (active_funds_exist.length === 0) {

            setApiLoader(false);
        }
        if (passive_funds_exist.length === 0) {
            setApiLoader(false);
        }

        const url = "/model-portfolio/upsert";

        let active_funds = fundTypes.flatMap((fund) => {
            return fund.funds.map((item) => ({
                "fund_id": item.fund_id,
                "basket_type": 'ACTIVE',
                "allocation_id": item.allocation_id,
                "allocation": parseFloat(fund.allocation),
                "asset_class": fund.fund_type,
                "primary_fund": item.primary_fund
            }));
        });

        let passive_funds = passiveFundTypes.flatMap((fund) => {
            return fund.funds.map((item) => ({
                "fund_id": item.fund_id,
                "basket_type": 'PASSIVE',
                "allocation_id": item.allocation_id,
                "allocation": parseFloat(fund.allocation),
                "asset_class": fund.fund_type,
                "primary_fund": item.primary_fund
            }));
        });


        const request = {
            "investment_style_id": location.state.type,
            "start": startYear.value,
            "end": endYear.value,
            "is_primary": isPrimary,
            "funds": {
                "active": {
                    "fund_data": active_funds,
                    "benchmark_data": {
                        "benchmark_id": null,
                        "benchmark_name": benchmark === "" ? null : benchmark,
                        "gold": gold === "" ? null : parseFloat(gold),
                        "equity": equity === "" ? null : parseFloat(equity),
                        "debt": debt === "" ? null : parseFloat(debt),
                        "returns": returns === "" ? null : parseFloat(returns)
                    }
                },
                "passive": passive_funds_exist.length === 0 ? null : {
                    "fund_data": passive_funds,
                    "benchmark_data": {
                        "benchmark_id": null,
                        "benchmark_name": passiveBenchmark === "" ? null : passiveBenchmark,
                        "gold": passiveGold === "" ? null : parseFloat(passiveGold),
                        "equity": passiveEquity === "" ? null : parseFloat(passiveEquity),
                        "debt": passiveDebt === "" ? null : parseFloat(passiveDebt),
                        "returns": passiveReturns === "" ? null : parseFloat(passiveReturns)
                    }
                }
            }
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss()
            if (response.status_code === 200) {
                navigate("/manage-portfolio", {
                    state: {
                        index: 1,
                        tab_index: location.state.type
                    }
                });
                toast.success('Portfolio updated', {
                    type: 'success'
                })
            } else {

                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLoader(false);
        })

    }

    let fund_list = tabIndex === 1 ? fundTypes : passiveFundTypes;

    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <div className="padding-110px-bottom">
                    <Breadcrumb handleNavigate={_handleNavigate}
                        mainTitle="Manage modal portfolios"
                        subTitle={`${location.state.portfolio !== null ? "Update" : "Create"} model portfolio`} />
                </div>
            </div>
            <div className="e-body-wrapper">
                <div className="e-bg-white border-radius-32px padding-40px-tb padding-32px-lr">
                    <h6 className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-dark-charcoal mb-4">Manage model portfolio</h6>
                    <div className="e-bg-snowy-lavender border-radius-16px py-4 padding-32px-lr" ref={yearRef}>
                        <label className="e-montserrat-semi-bold color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-3">Duration
                            <span className='color-deep-carmine-pink e-montserrat-regular'>*</span>
                        </label>
                        <div className="row" >
                            <div className="col-lg-4 col-md-6 pb-md-0 pb-4 mb-1">
                                <CustomSelect
                                    type={3}
                                    label="Start year"
                                    placeholder="Select year"
                                    postFix={true}
                                    options={startYearOptions}
                                    value={startYear}
                                    error={startYearError}
                                    selectChange={_handleStartYearValue} />
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <CustomSelect
                                    type={3}
                                    label="End year"
                                    placeholder="Select year"
                                    postFix={true}
                                    options={endYearOptions}
                                    value={endYear}
                                    error={endYearError}
                                    selectChange={_handleEndYearValue} />
                            </div>
                        </div>
                        <label className="e-montserrat-semi-bold color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-3 mt-4">Portfolio type
                            <span className='color-deep-carmine-pink e-montserrat-regular'>*</span>
                        </label>
                        <CustomCheckBox
                            checked={isPrimary}
                            label="Primary"
                            onChange={_handlePrimary} />
                    </div>
                    <h6 className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-dark-charcoal my-4">Asset allocation</h6>
                    <div className="d-flex align-items-center row">
                        <div className="col-6">
                            <CustomTabBar
                                selectedTab={tabIndex}
                                data={BASKET_OPTIONS}
                                onSelectTab={_handleTabIndex} />
                        </div>
                        <div className="col-6 d-flex justify-content-end align-items-center">
                            <p className="color-gray e-montserrat-semi-bold e-font-14 e-line-height-20">
                                <img src={require("../../../../Assets/Images/Icons/info.svg").default}
                                    width="14px"
                                    alt="info" />
                                Total allocation should be <span className="e-montserrat-bold">100%</span>
                            </p>
                        </div>

                    </div>
                    <div className="e-bg-snowy-lavender border-radius-16px py-4 mt-4 padding-32px-lr" ref={basketRef}>
                        {

                            fund_list.map((item, index) => {
                                return (
                                    <div className="border-bottom-1px border-color-mint-gray py-3">
                                        <div className={index > 0 ? "row align-items-center" : "row align-items-center mb-4 pb-1"}>
                                            <div className="col-lg-4 col-md-6">
                                                <CustomTextInput1
                                                    label="Fund Type"
                                                    placeHolder="Add fund type"
                                                    postFix={true}
                                                    value={item.fund_type}
                                                    error={item.fund_error}
                                                    handleChange={(input_value) => _handleFundType(input_value, index)} />
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <PercentageInput
                                                    postfix="*"
                                                    error={item.allocation_error}
                                                    placeHolder="Allocation"
                                                    label="Allocation"
                                                    value={item.allocation}
                                                    handleChange={(input_value) => _handleAllocation(input_value, index)} />
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                {
                                                    fund_list.length > 1 && tabIndex === 1 ? <LinkButton text="Delete fund type" className="ms-auto"
                                                        handleClick={() => _handleDeleteFund(index, 0, false)} />
                                                        :
                                                        tabIndex === 2 ?
                                                            <LinkButton text="Delete fund type" className="ms-auto"
                                                                handleClick={() => _handleDeleteFund(index, 0, false)} />
                                                            :
                                                            null
                                                }
                                            </div>
                                        </div>
                                        {
                                            item.funds.map((fund, key) => {
                                                return (
                                                    <div className="row mt-4 d-flex align-items-center">
                                                        <div className="col-lg-4 col-md-6 ">
                                                            <p className={`e-montserrat-medium e-font-14 e-line-height-22 margin-6px-bottom color-dark-charcoal`}>
                                                                Add fund
                                                                <span className='color-deep-carmine-pink'>{"*"}</span>
                                                            </p>
                                                            <SearchSelect data={fundList}
                                                                value={fund.fund}
                                                                error={fund.error[0]}
                                                                selectedFund={fund.fund_id}
                                                                selectFund={(fund) => _handleFundValue(fund.name, index, 0, key, fund.id)}
                                                                onFocus={() => _getFundList(null)}
                                                                valueChange={(input_value) => {
                                                                    _getFundList(input_value === "" ? null : input_value);
                                                                    _handleFundValue(input_value, index, 0, key, null);
                                                                }} />
                                                        </div>

                                                        <div className="col-lg-3 col-md-11 pt-4 ">
                                                            <CustomCheckBox label="Set as primary fund"
                                                                checked={fund.primary_fund}
                                                                onChange={(check) => _handlePrimaryFund(check, index, key)} />
                                                        </div>
                                                        <div className="col-lg-1 col-1 pt-3">
                                                            {
                                                                item.funds.length > 1 ? <Icon icon="delete"
                                                                    size={24}
                                                                    className="cursor-pointer e-delete-hover"
                                                                    // color={key === onDeleteHover && "#f6172d"}
                                                                    // onMouseEnter={() => _handleDeleteHover(key)}
                                                                    // onMouseLeave={() => _handleDeleteHover(null)}
                                                                    onClick={() => _handleDeleteFund(index, key, true)} />
                                                                    :
                                                                    fund_list.length > 1 ? <Icon icon="delete"
                                                                        size={24}
                                                                        className="cursor-pointer e-delete-hover"
                                                                        // color={key === onDeleteHover && "#f6172d"}
                                                                        // onMouseEnter={() => _handleDeleteHover(key)}
                                                                        // onMouseLeave={() => _handleDeleteHover(null)}
                                                                        onClick={() => _handleDeleteFund(index, key, false)} />
                                                                        :
                                                                        null}
                                                        </div>
                                                        {/* {key === item.funds.length - 1 && <div className="col-lg-4 pt-4">
                                                            <div className="color-rebecca-purple e-montserrat-semi-bold e-font-16 e-line-height-14 gap-8px padding-12px-tb d-flex align-items-center w-max-content cursor-pointer"
                                                                onClick={() => _handleAddSecondaryFund(index)}>
                                                                <Icon icon="add"
                                                                    size={24} />Add secondary fund</div>
                                                        </div>} */}

                                                    </div>
                                                )
                                            })
                                        }
                                        {<div className="col-12 pt-3">
                                            <div className="color-rebecca-purple e-montserrat-semi-bold e-font-16 e-line-height-14 gap-8px padding-12px-tb d-flex align-items-center w-max-content cursor-pointer"
                                                onClick={() => _handleAddSecondaryFund(index)}>
                                                <Icon icon="add"
                                                    size={24} />Add secondary fund</div>
                                        </div>}

                                    </div>
                                )
                            })
                        }
                        {<div className="col-12 pb-3">
                            <PrimaryButton label="Add new fund type"
                                className="padding-14px-tb px-4 bg-white color-rebecca-purple border-rebecca-purple margin-32px-top"
                                handleClick={_handleAddFund} />
                        </div>}

                        {/* <div className="w-100 border-top-1px border-color-mint-gray my-3"></div> */}
                        <Fragment>
                            <label className="e-montserrat-semi-bold color-dark-charcoal e-font-14 e-line-height-18 cursor-text my-3">
                                Benchmark allocation
                            </label>
                            <div className="row">
                                <div className="col-lg-4" ref={benchmarkRef}>
                                    <p className={`e-montserrat-medium e-font-14 e-line-height-22 margin-6px-bottom color-dark-charcoal mb-2`}>
                                        Choose benchmark
                                    </p>
                                    <CustomTextInput1
                                        value={tabIndex === 1 ? benchmark : passiveBenchmark}
                                        placeHolder="Benchmark"
                                        handleChange={(input_value) => _handleBenchmark(input_value)} />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-4 col-md-6 pb-4">
                                    <PercentageInput
                                        label="Equity"
                                        value={tabIndex === 1 ? equity : passiveEquity}
                                        placeHolder="Equity allocation"
                                        handleChange={_handleEquity} />
                                </div>
                                <div className="col-lg-4 col-md-6  pb-4">
                                    <PercentageInput
                                        label="Debt"
                                        value={tabIndex === 1 ? debt : passiveDebt}
                                        placeHolder="Debt allocation"
                                        handleChange={_handleDebt} />
                                </div>

                                <div className="col-lg-4 col-md-6  pb-4">
                                    <PercentageInput
                                        label="Equity BSE"
                                        value={tabIndex === 1 ? equityBse : passiveEquityBse}
                                        placeHolder="Equity BSE allocation"
                                        handleChange={_handleEquityBse} />
                                </div>

                                <div className="col-lg-4 col-md-6  pb-4">
                                    <PercentageInput
                                        label="Gold"
                                        value={tabIndex === 1 ? gold : passiveGold}
                                        placeHolder="Gold allocation"
                                        handleChange={_handleGold} />
                                </div>

                                <div className="col-lg-4 col-md-6  pb-4">
                                    <PercentageInput
                                        label="Returns"
                                        postfix="*"
                                        error={tabIndex === 1 ? returnsError : passiveReturnsError}
                                        value={tabIndex === 1 ? returns : passiveReturns}
                                        placeHolder="Returns"
                                        handleChange={_handleReturns} />
                                </div>
                            </div>
                        </Fragment>
                    </div>
                    <div className="d-flex justify-content-end gap-8px padding-40px-top">
                        <SecondaryButton label="Cancel"
                            className="padding-14px-tb padding-50px-lr"
                            handleClick={() => navigate("/manage-portfolio")} />
                        <PrimaryButton label={location.state.portfolio !== null ? "Update" : "Create"}
                            loader={apiLoader}
                            disabled={apiLoader}
                            className="padding-14px-tb padding-50px-lr"
                            handleClick={_handleCreatePortfolio} />
                    </div>
                    <div className=' d-flex align-items-center justify-content-end margin-6px-top '>
                        <p className='color-deep-carmine-pink  e-montserrat-medium e-font-12  e-line-height-16 mb-0 '>
                            {error}
                        </p>
                    </div>
                </div>
            </div>
            {/* {
                confirmFunds === true && <DeleteConfirmationModal delete={_confirm}
                    description="Since there are no passive funds available in suggested combination, kindly proceed with active funds. Passive funds shall be made available when we get such desired allocation"
                    label="Confirm"
                    close={() => {
                        _handleConfirmation(false)
                    }}
                    updateList={() => { _managePortfolio(false) }} />
            } */}
        </Fragment >
    )
}
export default ManageModelPortfolio;
