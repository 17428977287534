
/*
 *   File : view-ticket.js
 *   Author URI : https://evoqins.com
 *   Description : Order detail modal 
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Custom components
import { Icon } from "../Icon";
import { EmptyScreen } from "../Others";

// Custom styles
import style from "../../Styles/Components/modal.module.scss";
import APIService from "../../Services/api-service";
import { Badge } from "../Badge";
import Config from "../../Helper/config";


const ViewTicketModal = (props) => {

    const navigate = useNavigate();

    const [ticketDetail, setTicketDetail] = useState({});
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (props.status === false) {
            _getTicketDetails();
        }
        //eslint-disable-next-line
    }, [props.status]);

    useEffect(() => {
        var my_modal = document.getElementById("view-ticket");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);

    // API - ticket details
    const _getTicketDetails = () => {
        const url = "/support/detail";

        const request = {
            ticket_id: props.detail.ticket_id
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setTicketDetail(response.data);
            }
            setLoader(false);
        })
    }

    return (
        <Fragment>
            <div className={`modal fade ${style.e_progress_modal}`}
                id="view-ticket"
                tabIndex="-1"
                aria-labelledby="view-ticket"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-32px-all ${style.e_content} `}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                View ticket
                            </h5>
                            <Icon icon="close"
                                size={24}
                                id="close-modal"
                                data-bs-dismiss="modal"
                                className='cursor-pointer' />
                        </div>
                        {
                            loader === true ?
                                <div className="h-60vh d-flex align-items-center justify-content-center">
                                    <img src={require("../../Assets/Images/loader/page-loader.gif")}
                                        alt={"loader"}
                                        width={60}
                                        height={60}
                                    />
                                </div>
                                :
                                Object.keys(ticketDetail).length > 0 ?
                                    <div className={`mt-4 ${style.e_body_wrapper}`}>
                                        <div className="border-1px border-color-mint-gray p-4 border-radius-16px">
                                            <p className="mb-3 color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 border-bottom-1px border-color-mint-gray pb-3">Subject: <span className="color-dark-charcoal e-montserrat-semi-bold">{props.detail.subject}</span></p>
                                            <div className="row">
                                                <div className="col-4">
                                                    <span className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 margin-2px-bottom">ID</span>
                                                    <p className="color-dark-charcoal e-font-14 e-line-height-20 e-montserrat-semi-bold mb-3">{ticketDetail.ticket_no}</p>
                                                </div>
                                                <div className="col-4">
                                                    <span className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 margin-2px-bottom">Created on</span>
                                                    <p className="color-dark-charcoal e-font-14 e-line-height-20 e-montserrat-semi-bold mb-3">{ticketDetail.created_on}</p>
                                                </div>
                                                <div className="col-4">
                                                    {ticketDetail.image && <Fragment>
                                                        <span className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 margin-2px-bottom">Screenshot/Photo</span>
                                                        <button className="e-customize-btn  e-font-14 e-line-height-18 e-montserrat-semi-bold py-1 px-2 d-flex gap-4px align-items-center text-truncate w-200px" onClick={() => window.open(ticketDetail.image, "_blank")}>
                                                            <img src={require('../../Assets/Images/Tickets/image.png')} alt="ticket"
                                                                width={18}
                                                                height={16} />
                                                            <p className="margin-2px-bottom text-truncate">{ticketDetail.image ? ticketDetail.image.split("?")[0].split("/").splice(-1) : "-"}</p>
                                                        </button>
                                                    </Fragment>}
                                                </div>

                                                <div className="col-12">
                                                    <span className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 margin-2px-bottom">Description</span>
                                                    <p className="color-dark-charcoal e-font-14 e-line-height-20 e-montserrat-semi-bold mb-3 word-wrap">
                                                        {ticketDetail.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-1px border-color-mint-gray p-4 border-radius-16px mt-3">
                                            <p className="mb-3 color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 border-bottom-1px border-color-mint-gray pb-3">
                                                Basic profile information & account status
                                            </p>
                                            <div className="row">

                                                <div className="col-4">
                                                    <p className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 margin-2px-bottom">Name</p>
                                                    <p className="color-dark-charcoal e-font-14 e-line-height-20 e-montserrat-semi-bold mb-3">{ticketDetail.username}</p>
                                                </div>
                                                <div className="col-5">
                                                    <p className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 margin-2px-bottom">Email</p>
                                                    <p href={`mailto:${ticketDetail.email}`} className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-rebecca-purple mb-0 text-decoration-hover-underline cursor-pointer">
                                                        {ticketDetail.email === null ? "-" : ticketDetail.email}
                                                    </p>
                                                </div>
                                                <div className="col-3">
                                                    <p className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 margin-2px-bottom">Phone</p>
                                                    <p href={`tel:+91${ticketDetail.mobile}`} className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-rebecca-purple mb-0 text-decoration-hover-underline cursor-pointer">
                                                        {ticketDetail.mobile === null ? "-" : `+91-${ticketDetail.mobile}`}
                                                    </p>
                                                </div>
                                                <div className="col-4">
                                                    <p className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 margin-2px-bottom">Account status</p>
                                                    <p className="color-dark-charcoal e-font-14 e-line-height-20 e-montserrat-semi-bold mb-3">{ticketDetail.account_status}</p>
                                                </div>
                                                <div className="col-4">
                                                    <p className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 margin-2px-bottom">Ticket status</p>
                                                    <p className="color-dark-charcoal e-font-14 e-line-height-20 e-montserrat-semi-bold mb-3">{ticketDetail.ticket_status}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            ticketDetail.orders.length !== 0 &&
                                            <div className="border-1px border-color-mint-gray pt-4 px-4 pb-2 border-radius-16px mt-4">
                                                <p className="mb-3 color-dark-gray e-montserrat-medium e-font-14 e-line-height-20 border-bottom-1px border-color-mint-gray pb-3">
                                                    Last {ticketDetail.orders.length === 1 ? "1 order" : `${ticketDetail.orders.length} orders`}
                                                </p>
                                                <div className="row pb-3">
                                                    <div className="col-4">
                                                        <span className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20">Order ID</span>

                                                    </div>
                                                    <div className="col-4">
                                                        <span className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20">Created on</span>
                                                    </div>

                                                    <div className="col-4">
                                                        <span className="color-dark-gray e-montserrat-medium e-font-14 e-line-height-20">Status</span>
                                                    </div>

                                                </div>
                                                {
                                                    ticketDetail.orders.map((item, index) => {
                                                        return (
                                                            <div key={index} className={index > 0 ? 'border-top-1px border-color-mint-gray py-2' : 'pb-2'}>
                                                                <div className="row align-items-center">
                                                                    <div className="col-4">
                                                                        <p className="color-dark-charcoal e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 cursor-pointer"
                                                                            data-bs-dismiss="modal"
                                                                            onClick={() => navigate("/orders/detail", {
                                                                                state: {
                                                                                    id: item.order_id
                                                                                }
                                                                            })}>{item.order_no}</p>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <p className="color-dark-charcoal e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0">{item.created}</p>
                                                                    </div>

                                                                    <div className="col-4">
                                                                        <Badge color={item.status === "Successful" ? Config.BADGE_COLORS.green :
                                                                            item.status === "Pending" ? Config.BADGE_COLORS.orange : Config.BADGE_COLORS.red} status={item.status} />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }

                                    </div>
                                    :
                                    <div className={`mt-4 ${style.e_body_wrapper}`}>
                                        <EmptyScreen className="my-5"
                                            title="No ticket detail found"
                                            image={require("../../Assets/Images/loader/empty-funds.png")}
                                            description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                    </div>
                        }
                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default memo(ViewTicketModal)
