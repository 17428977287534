/*
 *   File : external-investments.js
 *   Author : https://evoqins.com
 *   Description : External Investments
 *   Version : 1.0.0
*/
// packages
import { Fragment, useEffect, useState } from "react";

// components
import { CustomTabBar } from "../../../../Components/Tab";
import { EmptyScreen } from "../../../../Components/Others";
import { HorizontalStackedBar } from "../../../../Components/Chart";
import { DataTableContainer } from "../../../../Components/DataTable";

// services
import APIService from "../../../../Services/api-service";

// styles
import Colors from '../../../../Styles/color.module.scss'


const INVESTMENT_TYPES = [
    {
        label: "Overview",
        id: 1
    },
    {
        label: "Categories",
        id: 2
    },
    {
        label: "Funds",
        id: 3
    },
];

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',

            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },
            // '&:hover': {
            //     backgroundColor: Colors.lavender_mist
            // },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};

const FILTER_OPTIONS = [
    {
        label: "Live Folio",
        id: 2
    },
    {
        label: "Zero Balance",
        id: 1
    },
]

const ExternalInvestments = (props) => {
    const [loader, setLoader] = useState(true);
    const [summaryData, setSummaryData] = useState({});
    const [investmentType, setInvestmentType] = useState(INVESTMENT_TYPES[0].id);
    const [fundAllocationData, setFundAllocationData] = useState([]);
    const [categoryAllocationData, setCategoryAllocationData] = useState([]);
    const [externalData, setExternalData] = useState({});
    const [pageNumber, setPageNumber] = useState(1);
    const [selected, setSelected] = useState(FILTER_OPTIONS[0].id);
    const [listLoader, setListLoader] = useState(true);

    useEffect(() => {
        _getExternalSummary();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        _getExternalList();

        // eslint-disable-next-line
    }, [pageNumber, selected])


    function _handleInvestmentType(tab_index) {
        setInvestmentType(tab_index);
    }

    function _handleFilter(tab_index) {
        setSelected(tab_index);
    }

    const _handlePageChange = (page) => {
        setPageNumber(page);
    };

    // API - external summary
    const _getExternalSummary = () => {
        const url = "/user/external-portfolio-summary";
        const request = {
            customer_id: props.id,
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setSummaryData(response.data);
                const FUND_ALLOCATION_DATA = response.data.funds.graph_data !== undefined && response.data.funds.graph_data.map((item) => {
                    return (
                        {
                            name: item.asset_class,
                            ...item
                        }
                    )
                })

                const CATEGORY_ALLOCATION_DATA = response.data.categories.graph_data !== undefined && response.data.categories.graph_data.map((item) => {
                    return (
                        {
                            name: item.asset_class,
                            ...item
                        }
                    )
                })
                setFundAllocationData(FUND_ALLOCATION_DATA);
                setCategoryAllocationData(CATEGORY_ALLOCATION_DATA);
            }
            else {
                setSummaryData({});
            }
            setLoader(false);
        });

    }

    // API - External List
    const _getExternalList = () => {
        const url = "/user/list-external-portfolio";
        const request = {
            customer_id: props.id,
            "page_num": pageNumber,
            "page_size": 15,
            "filter": selected
        }
        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setExternalData(response.data);
            } else {
                setExternalData({});
            }
            setListLoader(false);
        })
    }

    const HOLDINGS_COLUMNS = [
        {
            name: "Folio No.",
            selector: row => row.folio_no === null ? "-" : row.folio_no,
            width: '140px'
        },

        {
            name: "Current amount",
            cell: row => row.current_amount === null ? "-" : row.current_amount,
            width: "160px"
        },
        {
            name: "Invested amount",
            cell: row => row.invested_amount === null ? "-" : row.invested_amount,
            width: "160px"
        },
        {
            name: "Monthly SIP",
            cell: row => row.monthly_sip === null ? "-" : row.monthly_sip,
            width: "160px"
        },
        {
            name: "ARN Code",
            selector: row => row.arn_code === null ? "-" : row.arn_code,
            width: '120px'
        },
        {
            name: "Returns",
            cell: row => <span className={`${row.return > 0 ? "color-emerald-green" : row.return < 0 ? "color-deep-carmine-pink" : "color-eerie-black"}`}>
                {row.return_text}
            </span>,
            width: '160px'
        },
        {
            name: "Purchase date",
            selector: row => row.purchase_date === null ? "-" : row.purchase_date,
            width: '140px'
        },
        {
            name: "Purchase NAV",
            selector: row => row.purchase_nav === null ? "-" : row.purchase_nav,
            width: '140px'
        },
        {
            name: "Units",
            selector: row => row.units === null ? "-" : row.units,
            width: '100px'
        },
        {
            name: "XIRR",
            selector: row => row.xirr === null ? "-" : row.xirr,
            width: '100px'
        },
    ]
    return (
        <Fragment>
            <div className="bg-white border-radius-24px padding-32px-tb mt-4">
                <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-4 padding-32px-lr">
                    External  Investments
                </h4>
                {
                    loader === true ?
                        <div className="h-80vh d-flex align-items-center justify-content-center">
                            <img src={require("../../../../Assets/Images/loader/page-loader.gif")}
                                alt={"loader"}
                                width={60}
                                height={60}
                            />
                        </div>
                        :
                        <Fragment>
                            {Object.keys(summaryData).length > 0 && <div className="col-md-8 pb-4">
                                <div className="e-box-shadow-white border-radius-24px pt-3 margin-32px-lr pb-3 e-portfolio-section d-flex flex-column">
                                    <div className="border-bottom-1px border-color-mint-gray d-sm-block d-none  padding-32px-lr">
                                        <CustomTabBar data={INVESTMENT_TYPES}
                                            selectedTab={investmentType}
                                            onSelectTab={_handleInvestmentType} />
                                    </div>
                                    {
                                        investmentType === 1 ?
                                            <Fragment>
                                                <div className="padding-32px-lr d-flex align-items-center justify-content-between border-bottom-1px border-color-mint-gray py-sm-3 py-2">
                                                    <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                                                        <img src={require('../../../../Assets/Images/Home/total-returns.png')}
                                                            alt="total-returns"
                                                            width={28}
                                                            draggable={false} />
                                                        Total Returns
                                                    </div>
                                                    <span className={`${summaryData.total_investment.returns_amount > 0 ? 'color-emerald-green' : summaryData.total_investment.returns_amount < 0 ? 'color-deep-carmine-pink' : 'color-eerie-black'} e-font-20 e-line-height-24 e-montserrat-semi-bold`}>
                                                        {summaryData.total_investment.returns_amount_text}
                                                        <span className={`${summaryData.total_investment.returns_percentage > 0 ? 'color-emerald-green' : summaryData.total_investment.returns_percentage < 0 ? 'color-deep-carmine-pink' : 'color-eerie-black'} e-font-16 letter-spacing-02em`}>({summaryData.total_investment.returns_percentage_text})</span>
                                                    </span>
                                                </div>

                                                <div className="padding-32px-lr d-flex align-items-center justify-content-between  border-bottom-1px border-color-mint-gray py-sm-3 py-2">
                                                    <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                                                        <img src={require('../../../../Assets/Images/Home/current.png')}
                                                            alt="total-returns"
                                                            width={28}
                                                            draggable={false} />
                                                        Current
                                                    </div>
                                                    <span className="color-eerie-black e-font-18 e-line-height-24 e-montserrat-semi-bold letter-spacing-02em">{summaryData.total_investment.current_amount_text}</span>
                                                </div>

                                                <div className="padding-32px-lr d-flex align-items-center justify-content-between   border-bottom-1px border-color-mint-gray py-sm-3 py-2">
                                                    <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                                                        <img src={require('../../../../Assets/Images/Home/invested.png')}
                                                            alt="total-returns"
                                                            width={28}
                                                            draggable={false} />
                                                        Invested
                                                    </div>
                                                    <span className="color-eerie-black e-font-18 letter-spacing-02em e-line-height-24 e-montserrat-semi-bold">{summaryData.total_investment.invested_amount_text}</span>
                                                </div>

                                                <div className="padding-32px-lr d-flex align-items-center justify-content-between border-bottom-1px border-color-mint-gray py-sm-3 py-2">
                                                    <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                                                        <img src={require('../../../../Assets/Images/Home/xirr.png')}
                                                            alt="total-returns"
                                                            width={28}
                                                            draggable={false} />
                                                        XIRR
                                                    </div>
                                                    <span className={`${summaryData.total_investment.xirr > 0 ? "color-emerald-green" : summaryData.total_investment.xirr < 0 ? "color-deep-carmine-pink" : "color-eerie-black"} e-font-16 letter-spacing-02em e-line-height-24 e-montserrat-semi-bold d-flex align-items-center`}>
                                                        {summaryData.total_investment.xirr_text}
                                                        {summaryData.total_investment.xirr !== 0 &&
                                                            <img width={16} alt="xirr" src={summaryData.total_investment.xirr > 0 ? require("../../../../Assets/Images/Home/xirr-high.svg").default : require("../../../../Assets/Images/Home/xirr-low.png")} />
                                                        }
                                                    </span>
                                                </div>

                                                <div className="padding-32px-lr d-flex align-items-center justify-content-between py-sm-3 py-2">
                                                    <div className="d-flex align-items-center gap-8px e-font-14 e-line-height-16 e-montserrat-medium color-jet-gray">
                                                        <img src={require('../../../../Assets/Images/Home/1-day-return.png')}
                                                            alt="total-returns"
                                                            width={28}
                                                            draggable={false} />
                                                        1 Day Return
                                                    </div>
                                                    <span className={`${summaryData.total_investment.one_day_returns > 0 ? "color-emerald-green" : summaryData.total_investment.one_day_returns < 0 ? "color-deep-carmine-pink" : "color-eerie-black"} e-font-18 letter-spacing-02em e-line-height-24 e-montserrat-semi-bold `}>{summaryData.total_investment.one_day_returns_text ? summaryData.total_investment.one_day_returns_text : 0}
                                                        <span className={`e-font-14 ${summaryData.total_investment.one_day_returns_percentage > 0 ? "color-emerald-green" : summaryData.total_investment.one_day_returns_percentage < 0 ? "color-deep-carmine-pink" : 'color-eerie-black'}`}>({summaryData.total_investment.one_day_returns_percentage_text ? summaryData.total_investment.one_day_returns_percentage_text : 0})</span>
                                                    </span>
                                                </div>
                                                {summaryData.updated_as_on && <div className="color-black e-font-14 e-line-height-16 e-montserrat-regular text-center mb-0 mt-auto mb-1"> Data as on {summaryData.updated_as_on}</div>}
                                            </Fragment>
                                            :
                                            investmentType === 2 ?
                                                <div className="pb-2">
                                                    <div className="py-3 border-bottom-1px border-color-mint-gray mb-sm-3 mb-1  padding-32px-lr">
                                                        <HorizontalStackedBar height="h-32px" allocations={categoryAllocationData || []} />
                                                    </div>

                                                    <div className="row  padding-32px-lr">
                                                        <div className="col-sm-6 col-5">
                                                            <span className="color-black e-font-14 e-line-height-16 e-montserrat-semi-bold">Category</span>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <span className="color-black e-font-14 e-line-height-16 e-montserrat-semi-bold">Amount</span>
                                                        </div>
                                                        <div className="col-3 text-sm-center text-end">
                                                            <span className="color-black e-font-14 e-line-height-16 e-montserrat-semi-bold">Allocation(%)</span>
                                                        </div>
                                                    </div>
                                                    {
                                                        summaryData.categories.allocation_data !== undefined && summaryData.categories.allocation_data.map((item, index) => {
                                                            return (
                                                                <div className={`py-3 ${index > 0 ? 'border-top-point5px border-onyx' : ''}`}>
                                                                    <div className={`row align-items-center row-gap-12px  padding-32px-lr`}>
                                                                        <div className="col-sm-6 col-5 d-flex align-items-center gap-4px">
                                                                            <span style={{ background: item.color }}
                                                                                className="d-block rounded-circle w-10px h-10px "></span>
                                                                            <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-medium mb-0">{item.name}</p>
                                                                        </div>
                                                                        <div className="col-3 text-center">
                                                                            <span className="color-jet-gray e-font-14 e-line-height-14 e-montserrat-bold">{item.current_amount_text}</span>
                                                                        </div>
                                                                        <div className="col-3 text-sm-center text-end">
                                                                            <span className="color-jet-gray e-font-14 e-line-height-14 e-montserrat-bold">{item.allocation}%</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </div>

                                                :
                                                <div className="pb-2">
                                                    <div className="py-3 border-bottom-1px border-color-mint-gray mb-sm-3 mb-1  padding-32px-lr">
                                                        <HorizontalStackedBar height="h-32px" allocations={fundAllocationData || []} />
                                                    </div>

                                                    <div className="row  padding-32px-lr">
                                                        <div className="col-sm-6 col-5">
                                                            <span className="color-black e-font-14 e-line-height-16 e-montserrat-semi-bold">Fund name</span>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <span className="color-black e-font-14 e-line-height-16 e-montserrat-semi-bold">Amount</span>
                                                        </div>
                                                        <div className="col-3 text-sm-center text-end">
                                                            <span className="color-black e-font-14 e-line-height-16 e-montserrat-semi-bold">Allocation(%)</span>
                                                        </div>
                                                    </div>
                                                    {
                                                        summaryData.funds.allocation_data !== undefined && summaryData.funds.allocation_data.map((item, index) => {

                                                            return (
                                                                <div className={`py-3 ${index > 0 ? 'border-top-point5px border-color-mint-gray' : ''}`}>
                                                                    <div className={`row row-gap-12px align-items-center  padding-32px-lr`}>
                                                                        <div className="col-sm-6 col-5 d-flex align-items-center gap-4px">
                                                                            <span style={{ background: item.color }}
                                                                                className="d-block rounded-circle w-10px h-10px "></span>
                                                                            <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-medium mb-0">{item.mf_name}</p>
                                                                        </div>
                                                                        <div className="col-3 text-center">
                                                                            <span className="color-jet-gray e-font-14 e-line-height-14 e-montserrat-bold">{item.current_amount_text}</span>
                                                                        </div>
                                                                        <div className="col-3 text-sm-center text-end">
                                                                            <span className="color-jet-gray e-font-14 e-line-height-14 e-montserrat-bold">{item.allocation}%</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                    }
                                </div>
                            </div>}
                            <div className="row padding-32px-lr">
                                <div className="col-lg-3">
                                    <CustomTabBar data={FILTER_OPTIONS}
                                        selectedTab={selected}
                                        onSelectTab={_handleFilter} />
                                </div>
                            </div>
                            {
                                listLoader === true ?
                                    <div className="h-80vh d-flex align-items-center justify-content-center">
                                        <img src={require("../../../../Assets/Images/loader/page-loader.gif")}
                                            alt={"loader"}
                                            width={60}
                                            height={60}
                                        />
                                    </div>
                                    :
                                    Object.keys(externalData).length === 0 ?
                                        <EmptyScreen className="my-5"
                                            title="No External Investments found"
                                            image={require("../../../../Assets/Images/loader/empty-funds.png")}
                                            description="" />
                                        :
                                        <div className="position-relative">
                                            <DataTableContainer data={externalData.data}
                                                columns={HOLDINGS_COLUMNS}
                                                customStyles={TABLE_STYLE}
                                                selectableRows={false}
                                                paginationComponentOptions={{ noRowsPerPage: true }}
                                                defaultSortFieldId={1}
                                                pagination={true}
                                                paginationServer={true}
                                                perPage={12}
                                                defaultSortAsc={false}
                                                onChangePage={_handlePageChange}
                                                paginationTotalRows={externalData.total_count}
                                                paginationDefaultPage={externalData.current_page}
                                                selectRows={(allSelected, selectedCount, selectedRows) => {
                                                }}
                                            />
                                            <span className="e-montserrat-regular e-font-14 e-line-height-18 padding-32px-lr position-absolute color-jett-black bottom-10px">Showing {externalData.record} of {externalData.total_count} entries</span>
                                        </div>
                            }
                        </Fragment>
                }
            </div>
        </Fragment>
    )
}

export default ExternalInvestments